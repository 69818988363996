select {
  width: 10em;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
}

.fullsearchholder {
  width: 100%;
  overflow: hidden;
}

.searcharea {
  margin-top: 5px;
  width: 65%;
}

.copy {
  float: left;
  background-color: #bdccc1;
  width: 30%;
}

.searchtextarea:focus {
  outline: 2px solid #bdccc1;
  border: none !important;
}

.longinput {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.medinput {
  width: 60%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.shortinput {
  width: 40%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {
  .group {
    flex-direction: column;
  }
}

.resultarea {
  position: relative;
  min-width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.checkbox-list {
  display: grid;
  flex-wrap: wrap;
  grid-gap: 10px;
  padding: 10px;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.checkbox-input {
  display: none;
}

.checkbox-label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
}

.checkbox-input:checked + .checkbox-label:before {
  content: "\2713";
  font-size: 16px;
  color: #333;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .checkbox-item {
    width: 100%;
  }  
  .infobox {
    display: none;
  }
  .searcharea {
    width: 100%;
  }
  .checkbox-list {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 1000px) {
  .infobox {
    height: 900px;
  }
}

.addressCrosshairShortInput {
  margin-left: 5em;
  margin-right: 1em;
  width: 5%;
  height: 30px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  text-align: center;
}

.addressCrosshairLongInput {
  margin-left: -20px;
  width: 90%;
  height: 40px;
  padding: 5px 10px;
  border: 2px solid #bdccc1;
  border-radius: 4px;
  font-size: 16px;
}

.form-group {
  display: flex;
}

.form-group p {
  width: 10%;
  margin: 0 10px;
  white-space: nowrap;
}

.dashboard button {
  font-family: Playfair Display;
  height: 40px;
  width: 150px;
  border-radius: 20px;
  border: none;
  background-color: #bdccc1;
  color: black;
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-right: 10px;
}

.dashboard button:hover {
  background-color: #9aa89e;
}

.dashboard button:active {
  transform: translateY(2px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.property-box {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
}

.property-box h2 {
  font-size: 24px;
  margin: 0 0 10px 0;
  text-align: center;
}

.property-box .property-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
}

.property-box .property-info div {
  flex-basis: 45%;
  font-size: 16px;
  text-align: center;
}

.property-box .property-info div strong {
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
}

.my-switch {
  position: relative;
  display: inline-flex;
  width: 60px;
  height: 32px;
}

.my-switch input {
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 25px;
  width: 25px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

.my-switch input:checked + .slider {
  background-color: #4caf50;
}

.my-switch input:checked + .slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

.slider.rounded {
  border-radius: 32px;
}

.slider.rounded:before {
  border-radius: 50%;
}

.numbermarker {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: -115px;
  
  border-radius: 50% 50% 50% 0;
  border: 4px solid #fff;
  width: 20px;
  height: 20px;
  transform: rotate(-45deg);
}

.numbermarker::after {
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
  background-color: #fff;
}