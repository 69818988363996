.addressScore {
    width: 50%;
    align-items: left;
    text-align: left;
}

.addressOverview {
    width: 25%;
}


@media only screen and (max-width: 767px) {
    .addressScore {
      width: 100%;
    }
    .addressOverview {
      width: 80%;
    }
  }
  
