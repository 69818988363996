
.property button {
    height: 40px;
    width: 150px;
    border-radius: 20px;
    border: none;
    background-color: #bdccc1;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-right: 10px;
  }
  
  .property button:hover {
    background-color: #212121;
  }
  
  .property button:active {
    transform: translateY(2px);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }

  .property-box {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
  }
  
  .property-box h2 {
    font-size: 24px;
    margin: 0 0 10px 0;
    text-align: center;
  }
  
  .property-box .property-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .property-box .property-info div {
    flex-basis: 45%;
    font-size: 16px;
    text-align: center;
  }
  
  .property-box .property-info div strong {
    display: block;
    font-size: 18px;
    margin-bottom: 5px;
  }

  .moreproperties {
    width: 50%;
    display: inline-block;
    padding-left: 2em; 
    padding-top: 1em;
  }

  .propertymapbox {
    width: 50%; 
    display: inline-block;
  }

  @media only screen and (max-width: 767px) {
    .moreproperties {
      width: 110%;
    }
    .propertymapbox {
      width: 100%;
      margin-left: 2em;
    }
  }
  

  .subgrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 0.1rem;
  }

  .streetview button {
    background-color: black;
    color: white;
    height: 0px;
    width: 0px;
    margin-right: 0px;
  }
  