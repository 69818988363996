@font-face {
  font-family: 'fontello';
  src: url('./fontello.woff2?50098157') format('woff2'),
    url('./fontello.woff?50098157') format('woff');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'fontello';
      src: url('../font/fontello.svg?4540706#fontello') format('svg');
    }
  }
  */
[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: auto;
  margin-right: 0;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-left-open-big:before {
  content: '\e800';
} /* 'î €' */
.icon-right-open-big:before {
  content: '\e801';
} /* 'î ' */
.icon-okay:before {
  content: '\e82c';
} /* 'î ¬' */
.icon-search:before {
  content: '\e854';
} /* 'î¡”' */
.icon-message:before {
  content: '\e886';
} /* '' */
.icon-edit:before {
  content: '\e8cf';
} /* '' */
.icon-upload:before {
  content: '\e83c';
} /* '' */
.icon-del:before { content: '\e8ca'; } /* '' */
.icon-right-arrow:before { content: '\e8d0'; } /* '' */
.icon-right:before { content: '\e802'; } /* '' */
.icon-left:before { content: '\e803'; } /* '' */