
.chatselectorbutton {
    font-size: 100%;
    width: 100%;
    text-align: center;
    cursor: pointer;
    background-color: #bdccc1;
    color: white;
    border: 0px solid #bdccc1;
    padding: 20px;
    box-sizing: border-box;
    margin: 0 auto;
    font-style: italic;
    margin-bottom: 5px;
    margin-top: -5px;
}

.chatselectorbutton:hover {
    cursor: pointer;
    outline: none;
    background-color: #ece8e1
}

@media only screen and (max-width: 768px){
    .chatselectorbutton{
        display: block;
        float: none;
        margin-top: -5px;
    }
}

@media (min-width: 401px) and (max-width: 615px) {
    .propertyregistrationradio {
        width: 70% !important;
        margin-left: -5% !important;
    }

    .radiotext {
        margin-left: 40% !important;
        width: 110% !important;
    }
}

@media (max-width: 400px) {
    .propertyregistrationradio {
        width: 70% !important;
        margin-left: -8% !important;
        margin-top: -10% !important;
        margin-bottom: -10% !important;
    }

    .radiotext {
        margin-left: 40% !important;
        width: 125% !important;
    }
}

.radiotext {
    margin-left: 40%;
    width: 140%;
    margin-top: -5%;
    margin-bottom: -20%;
}

.registration {
    background-color: white !important;
    border: 1px solid #bdccc1;
    width: 100%;
}