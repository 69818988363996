.btn-primary:hover {
  background: rgba(160, 93, 47, 0.9);
}
.btn-secondary:hover {
  background: rgba(0, 0, 0, 0.05);
}

.recommended button:hover {
  cursor: pointer;
}

.recommended button {
  background-color: #bdccc1;
  border: none,;
  border-radius: 1em;
  margin-left: 0em;
  font-size: 1.2em; 
}

.pinaddressbutton {
  height: 40px;
  width: 150px;
  border-radius: 20px;
  border: none;
  background-color: #bdccc1;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-right: 10px;
}

.pinaddressbutton:hover {
  background-color: #212121;
}

.pinaddressbutton:active {
  transform: translateY(2px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
