.pdetails li {
    margin-left: -1em;
    color: #bdccc1;
    font-weight: bold;
}

.pdetails strong {
    color: rgb(44, 41, 41);
    font-weight: 500;
}

.pdetails ul li:before {
    display: none;
}